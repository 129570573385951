.header {
  height: 80px !important;
  border-bottom: 1px solid rgba(1, 12, 21, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  z-index: 99999;
  background: #fff;

  .navbar {
    display: flex;
    align-items: center;
    gap: 16px;

    & p {
      margin: 0;

      @media (max-width: 500px) {
        display: none;
      }
    }

    & img {
      height: 34px;
      width: 34px;
    }
  }

  .navbar-middle {
    @media (max-width: 815px) {
      display: none;
    }
  }

  .navbar-search {
    width: 238px;
    height: 46px;
    background: #f3faff;
    border: 1px solid rgba(1, 12, 21, 0.1);
    border-radius: 8px;
    padding: 0;
    text-indent: 12px;

    &:focus {
      outline: none;
    }
  }
}

.sidebar-menu {
  padding: 26px;
  display: flex;
  flex-direction: column;
  // gap: 32px;
  position: fixed;
  top: 0;
  margin-top: 60px;

  &__items {
    display: flex;
    align-items: center;
    gap: 18px;
    cursor: pointer;
    color: rgba(1, 12, 21, 0.7);
  }
}

.active {
  & p {
    color: #0a6dcd !important;
    font-weight: 500;
    font-size: 14px;
  }

  & svg > path,
  svg > g > path {
    fill: #0a6dcd;
  }
}
