.collapsible-wrapper {
  border-bottom: 1px solid rgba(1, 12, 21, 0.1);
  width: 100%;
  background: #fff;
  height: 48px;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    all: unset;
    cursor: pointer;
  }

  & h3 {
    font-weight: 600;
  }

  &__content {
    background: #fff;
    padding: 24px;

    &__title {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}

.download-btn {
  width: 167px;
  height: 40px;
  color: rgba(10, 109, 205, 1);
  font-weight: 500;
  padding: 8px 16px 8px 16px;
  border-radius: 24px;
  border: 1px solid rgba(222, 241, 255, 1);
  background: linear-gradient(0deg, #def1ff, #def1ff);
  display: flex;
  align-items: center;
  gap: 12px;
}
