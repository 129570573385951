.ato-management {
  &__select-section {
    display: flex;
    justify-content: space-between;
    gap: 57px;

    @media (max-width: 800px) {
      gap: 10px;
    }

    @media (max-width: 500px) {
      flex-direction: column;
    }
  }

  &__table {
    margin-top: 48px;
    &__card {
      width: 100%;
      height: 478px;
      left: 260px;
      top: 243px;
      background: #ffffff;
      border-radius: 8px;
      padding: 33px 24px;
      overflow: scroll;

      &__filters {
        display: flex;
        gap: 24px;
        border-bottom: 1px solid rgba(1, 12, 21, 0.1);

        &__filter {
          display: flex;
          gap: 8px;
          align-items: center;
          cursor: pointer;
        }

        &__filter-badge {
          display: flex;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #f6f6f6;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.active-filter {
  border-bottom: 2px solid #4b8e64;
}

.badge {
  width: 77px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.pending {
  background: rgba(1, 12, 21, 0.1);
}

.rejected {
  background: #ff7168;
}

.revoked {
  background: #ff7168;
}

.approved {
  background: #adff87;
}

.actions-list {
  display: flex;
  flex-direction: column;

  &__single {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 0 12px;

    &:hover {
      background: #edfff4;
      border-left: 2px solid #4b8e64;
    }
  }
}

.reject-modal {
  &__heading {
    border-bottom: 1px solid rgba(1, 12, 21, 0.1);
  }

  &__form {
    margin-top: 32px;
  }
}

.reject-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: #ff7168;
  border-radius: 8px;
  margin-top: 24px;
  color: #fff;
  font-size: 16px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  margin-bottom: 24px;

  .btn-flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.approve-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: #4b8e64;
  border-radius: 8px;
  margin-top: 24px;
  color: #fff;
  font-size: 16px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  margin-bottom: 24px;

  .btn-flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
