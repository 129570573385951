button {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.reg-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: #4b8e64;
  border-radius: 8px;
  margin-top: 24px;
  color: #fff;
  font-size: 16px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  margin-bottom: 24px;

  .btn-flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.secondary-button {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: rgba(75, 142, 100, 0.2);
  border: 1px solid rgba(75, 142, 100, 0.7);
  border-radius: 8px;
  margin-top: 24px;
  color: #fff;
  font-size: 16px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  margin-bottom: 24px;

  .btn-flex {
    display: flex;
    align-items: center;
    gap: 10px;

    & p {
      color: #4b8e64;
    }
  }
}

.primary-button {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: #4b8e64;
  border: 1px solid rgba(75, 142, 100, 0.7);
  border-radius: 8px;
  margin-top: 24px;
  color: #fff;
  font-size: 16px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  margin-bottom: 24px;

  .btn-flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.remita-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: #4b8e64;
  border: 1px solid rgba(75, 142, 100, 0.7);
  border-radius: 8px;
  margin-top: 24px;
  color: #fff;
  font-size: 16px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  margin-bottom: 24px;
}

.empty-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: #4b8e64;
  border: 1px solid rgba(75, 142, 100, 0.7);
  border-radius: 4px;
  margin-top: 24px;
  color: #fff;
  font-size: 16px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  margin-bottom: 24px;
  padding: 0 20px;

  .btn-flex {
    display: flex;
    align-items: center;
    gap: 10px;

    & p {
      color: #fff;
    }
  }
}
