body {
  font-family: 'Source Sans Pro', sans-serif;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h5 {
  font-family: 'Source Sans Pro', sans-serif;
}

:where(.css-1s3dcof).ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #f3f4f7 !important;
  height: 100% !important;
}

:where(.css-dev-only-do-not-override-1s3dcof).ant-layout .ant-layout-header {
  height: 64px;
  padding-inline: 50px;
  color: rgba(0, 0, 0, 0.88);
  line-height: 64px;
  background: #fff !important;
}

:where(.css-dev-only-do-not-override-1s3dcof).ant-layout .ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #fff !important;
  transition: all 0.2s;
}

:where(.css-dev-only-do-not-override-1s3dcof).ant-select {
  width: calc(100% / 4) !important;

  @media (max-width: 500px) {
    width: 100% !important;
  }
}

:where(.css-dev-only-do-not-override-1s3dcof).ant-popover .ant-popover-inner {
  padding: 0px !important;
}

:where(.css-1s3dcof).ant-popover .ant-popover-inner {
  padding: 0px !important;
}

:where(.css-1s3dcof).ant-layout .ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #fff !important;
  transition: all 0.2s;
}

:where(.css-1s3dcof).ant-layout .ant-layout-header {
  height: 64px;
  padding-inline: 50px;
  color: rgba(0, 0, 0, 0.88);
  line-height: 64px;
  background: #fff !important;
}
