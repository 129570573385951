.summary {
  display: flex;
  gap: 20px;
  margin-top: 30px;

  @media (max-width: 780px) {
    flex-direction: column;
  }
}

.summary-card {
  width: calc(100% / 2);
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;
  height: 380px;

  @media (max-width: 780px) {
    width: 100%;
  }

  &__title {
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 780px) {
      flex-direction: column;
    }

    &__flex {
      display: flex;
      gap: 16px;

      & select {
        border: 1px solid #eee;
        border-radius: 8px;
        height: 40px;
        padding: 0 12px;
      }
    }
  }

  &__chart {
    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__item {
      width: 40%;

      @media (max-width: 780px) {
        width: 100%;
      }
    }

    &__details {
      width: 40%;
      border-radius: 4px;
      background: #f4faff;
      padding: 12px;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & h3 {
        font-size: 32px;
        margin: 0;
      }

      & p {
        margin: 0;
        color: #086ecd;
      }
    }
  }

  &__details {
    width: 60%;
    border-radius: 4px;
    border: 1px solid #eee;
    padding: 20px;
    background: #f2fafe;

    &__big {
      font-size: 18px;
      color: #0a6dcd;
      font-weight: 600;
    }

    &__small {
      font-size: 16px;
      color: #858796;
      font-weight: 600;
    }
  }
}

.page-title {
  margin: 32px 0;
}

.summary-card-full {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;
  //height: 380px;
  margin-top: 32px;

  &__title {
    display: flex;
    justify-content: space-between;

    & select {
      width: 200px;
      border: 1px solid #eee;
      border-radius: 8px;
      height: 40px;
    }
  }

  &__chart-wrap {
    display: flex;
    justify-content: space-between;
    &__chart {
      width: 50%;
    }
    &__details {
      width: 30%;
      border-radius: 4px;
      border: 1px solid #eee;
      padding: 20px;
      background: #f2fafe;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: scroll;
      gap: 32px;

      &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;

        & h3 {
          font-size: 32px;
          margin: 0;
        }

        & p {
          margin: 0;
          color: #086ecd;
        }
      }

      &__big {
        font-size: 18px;
        color: #0a6dcd;
        font-weight: 600;
      }

      &__small {
        font-size: 16px;
        color: #858796;
        font-weight: 600;
      }
    }
  }
}

.mb-16 {
  margin-bottom: 16px;
}
