.single-school {
  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 50px;

    & h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: #010c15;
      margin: 0;
    }

    & p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: rgba(1, 12, 21, 0.7);
    }
  }

  &__title-box {
    height: 48px;
    padding: 0px 16px;
    background: #fafcfa;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-top: 40px;
  }

  &__school-details {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__school-details__single {
    display: flex;
    justify-content: space-between;

    &__left {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #2d333c;
      margin: 0;
    }

    &__right {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #020e24;
      opacity: 0.7;
      margin: 0;
    }
  }

  &__ato {
    height: 54px;
    background: #ffffff;
    box-shadow: -4px 4px 13px 4px rgba(118, 128, 135, 0.08);
    border-radius: 4px;
    padding: 0 8px;
    display: flex;
    align-items: center;
  }

  &__btn-flex {
    display: flex;
    gap: 32px;
    margin-top: 40px;
  }
}

.download-doc {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #4b8e64;
  cursor: pointer;
}
