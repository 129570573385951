.confirm {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__img {
    margin-bottom: 10px;
  }

  & h2,
  & p {
    text-align: center;
    margin: 0;
  }

  &__btn-flex {
    display: flex;
    justify-content: space-between;
    gap: 5%;
    width: 100%;
  }
}
