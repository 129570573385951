.recommendation-wrapper {
  background: #fff;
  border-radius: 4px;
  padding: 24px;

  &__title {
    color: #010c15;
    font-size: 18px;
    font-weight: 700;
  }

  &__subtitle {
    color: rgba(1, 12, 21, 0.7);
    font-size: 14px;
    font-weight: 400;
  }

  &__selection {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__select {
      display: flex;
      flex-direction: column;

      & label {
        margin-bottom: 4px;
      }

      & select {
        width: 600px;
        height: 40px;
        border-radius: 8px;
      }
    }
  }
}

.recommendation-action {
  margin-top: 24px;
  background: #0a6dcd;
  width: 211px;
  height: 39px;
  color: #fff;
}

.divider {
  border: 0.5px solid #010c1526;
  margin: 24px 0;
}

.recommedation-result {
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(1, 12, 21, 0.1);
  background: #fafafa;
}
