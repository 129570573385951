.login {
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__card {
    width: 50%;
    margin-top: 100px;
    //height: 400px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 24px;

    @media (max-width: 500px) {
      width: 90%;
      box-shadow: none;
    }

    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 16px;
      padding-top: 50px;

      & h2 {
        color: #4b8e64;
        text-align: center;

        @media (max-width: 600px) {
          font-size: 20px;
        }
      }
    }
  }
}
